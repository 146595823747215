<template>
  <div>
    <span style="display:none;"> {{ parseFloat(getValue).toFixed(2) }}</span>
    <div :style="getElementStyle()" v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'">
      <el-row align="middle" v-if="!data.properties.hideLabel && data.properties.filed_content != 'Hide'
        ">
        <!-- <el-col :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24">
          <span>
            <label
              for="text"
              v-if="hasLabel"
              :style="getLabelStyles"
            >
              {{ data.label }}
            </label>
          </span>
          <span
            v-if="data.validations.required && hasLabel"
            style="color: red; font-weight: bold"
          >
            *
          </span>
        </el-col> -->
        <el-col :span="showLabel && isDefalutPosq ? 19 : 19">
          <span v-if="data.description" style="color: #c0c4cc; font-weight: bold">
            <el-tooltip :content="data.description" placement="top" effect="dark">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </span>
        </el-col>
      </el-row>
      <el-row :span="showLabel && !isDefalutPos ? 24 : data.description ? 20 : 24">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <el-button type="success" round @click="proceed">{{ data.label }} {{ data.validations.payAmount }} {{
            data.validations.currency }} </el-button>

          <!-- <el-row align="middle">
        
          <p
            v-if="hasError"
            style="color: red; font-size: 14px"
          >
            {{ errorMsg }}
          </p>
          <p
            v-if="isView"
            :style="getStyle"
          >
            <template v-if="form[data.key]">{{ form[data.key] }}</template>
            <template v-else>-</template>
          </p> -->
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <div v-if="isView">
        {{ form[data.key] }}
      </div>
      <div v-else>
        <el-input type="text" v-if="data.properties.filed_content != 'Hide'" :placeholder="data.placeholder || data.label"
          v-model="form[data.key]" :required="data.validations.required" :disabled="checkReadonly()"
          :readonly="readonly()" :suffix-icon="data.properties.el_icon" :minlength="data.properties.minlength_value
              ? data.properties.minlength_value
              : 1
            " :maxlength="data.properties.maxlength_value
      ? data.properties.maxlength_value
      : 64
    " @change="applyFormRules"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { mapGetters } from "vuex";

import { pay } from "../../../utils/swirepay/index";

import { bus } from "../../../main";

import Config from "../../../config/app";
export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "entityDataId", "entityData", "currentEntity", "currentStep"
  ],
  mounted() {
    // console.log(this.data.validations)
    console.log("entityData currentEntity currentStep", this.entityData, this.currentEntity, this.currentStep)
    console.log("redirect:",Config.APP_URL+this.data.validations.defaultRedirectUrl )
    this.loading = true;
    if (this.data.default_value && !this.form[this.data.key]) {
      //  this.form[this.data.key] = this.data.default_value;
      this.$set(this.form, this.data.key, this.data.default_value);
    }

    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      console.log("data", this.data.properties);
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
    this.loading = false;
    if (this.entityDataId) {
      this.getTemplateIdsData();
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getIntegratedList"]),

    ...mapGetters("templatesData", ["getTemplatesData"]),

    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `height:${this.data.height - 30}px; font-weight: 400; width: ${this.data.width
        }px;`
        : "height: 70px";
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getValue() {
      if (this.data.validations && this.data.validations.entityVariable && this.data.validations.entityVariable.key) {
        let fields = [{ ...this.data.validations.entityVariable }];
        // console.log("this.data.selected_fields",this.data.selected_fields);

        let data = {};
        if (Object.keys(this.form).length) {
          Object.keys(this.form).forEach(key => {
            if (key.indexOf("#") > -1) {
              data[key.split("#")[1]] = this.form[key];
            } else {
              if (fields[0].input_type === "CURRENCY") {
                data[key] = this.form[key];
              } else if (this.data.selected_fields) {
                data[key] = this.form[key];
              } else {
                data[key] = parseFloat(this.form[key]).toFixed(
                  this.data.decimals ? this.data.decimals : 2
                );
              }
            }
          });
        }
        fields.forEach(fl => {
          if (fl.template_id) {
            data[fl.key] = this.getTemplateValue(fl.template_id, fl.key);
          }
        });
        // data[this.data.validations.entityVariable.key]

        this.updateAmount(data[this.data.validations.entityVariable.key],)
        return data[this.data.validations.entityVariable.key]
      }
      else {
        return this.data.validations.payAmount ? this.data.validations.payAmount : 0
      }
      // return this.setValueBasedOnFields(fields, data);
    }

  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      inputValue: '',
      maskedValue: '',
      inputValue2: '',
    };
  },
  directives: {
    maskLastFour: {
      inserted(el) {
        el.addEventListener('blur', (event) => {
          const input = event.target;
          const inputValue = input.value;
          let maskedValue
          if (inputValue.length <= 4) {
            maskedValue = inputValue
          }
          else {
            let lastFourDigits = inputValue.slice(-4)
            maskedValue = '*'.repeat(inputValue.length - 4) + lastFourDigits
          }
          input.value = maskedValue;

          input.dispatchEvent(new Event('blur'));
        });
      }
    }
  },

  methods: {
    updateAmount(amount, currency) {
      this.data.validations.payAmount = amount;
      this.data.validations.currency = currency ? currency : this.data.validations.currency;
    },
    getTemplateValue(templateId, key) {
      let value = 0;
      if (this.getTemplatesData && this.getTemplatesData.length) {
        this.getTemplatesData.forEach(item => {
          if (item.template_id == templateId) {
            value =
              item.template_data && item.template_data[key]
                ? item.template_data[key]
                : "";
          }
        });
        return value ? value : "";
      }
    },
    async getTemplateIdsData() {
      let templateIds = [];

      let fields = [{ ...this.data.validations.entityVariable }];
      fields.forEach(element => {
        if (element.template_id) {
          if (templateIds.indexOf(element.template_id) < 0) {
            templateIds.push(element.template_id);
          }
        }
      });
      let params = {
        template_ids: templateIds,
        entity_data_id: this.entityDataId,
        status: "ACTIVE"
      };
      await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
    },

    async proceed() {
      //Proceed To check The Data

      await this.$store.dispatch("paymentGateway/fetchInitSystemsData");
      if (this.getIntegratedList && this.getIntegratedList.cred_details) {
        // console.log(this.data)

        let paymentSystem = this.getIntegratedList.cred_details.filter(
          (e) => e._id == this.data.validations.paymentSystems[0]
        );
        // console.log(paymentSystem)
        let bytes = await this.$CryptoJS.AES.decrypt(paymentSystem[0].crypto, paymentSystem[0].ivKey);
        let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
        console.log(decryptedData);

        // console.log("this.getTemplatesData",this.getTemplatesData)
         let customer = {
            "email": "info@esigns.io",
            "name": "e-Signs Anonymous",
            "phoneNumber": "+14084994322",
            "taxId": "NONE",
            "taxStatus": "exempt",
            "taxValue": null
          };
        //entity Type
         if(this.currentEntity.entity_type==="INDIVIDUAL")
         {
          // fetch default entity Info
          let getContactTemplate = this.currentEntity.templates &&this.currentEntity.templates[0];
          let getContactTemplateData = this.getTemplatesData.filter(tempData=>{
            return tempData.template_id === getContactTemplate.template_id;
          })
          console.log(getContactTemplateData)
          customer.email = getContactTemplateData[0].template_data.email;
          customer.name =  getContactTemplateData[0].template_data.name;
          customer.phoneNumber = "+91"+ getContactTemplateData[0].template_data.phone_number
         }

        // cred_details
        if (paymentSystem[0].pgType === 'SWIREPAY') {
          pay()
          bus.$emit('pay-button-action');
        //   let description = {
        //     entityDataId: this.entityDataId,
        //     location: window.location.href,
        //     updateRules: this.data.validations.updateRules,
        //     entityId: this.currentEntity._id,
        //     templateId: this.currentStep.template_id,
        //     key: this.data.key
        //   };
          // statementDescriptor
          // let defaultRedirectUrl="http://localhost:8080/"+"entity-payment-status-update?SessionData="+this.data.validations.paymentSystems[0]

        //   pay(decryptedData.stripeKey, this.data.validations.payAmount * 100, 0, this.data.validations.currency, this.data.description, this.data.validations && this.data.validations.defaultRedirectUrl ? Config.APP_URL+this.data.validations.defaultRedirectUrl : window.location.href, 'test', ["CARD", "ACH_LEGACY"], description,customer);
        }
      }

    },

    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      console.log(
        "filed type",
        this.fieldFilledByCurrentUser(this.data.filled_by)
      );
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    // rfrs sgwrer
    // ended
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
            this.data.styles.font &&
            this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
            this.data.styles.font &&
            (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
